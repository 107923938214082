<template>
  <v-container class="fill-height" fluid  style="height:100vh; min-height:600px;">
    <v-img :src="appConfig.pages.pageNotFound.images.headerBackground"  class="fill-height primary darken-4">
      <v-container fluid justify="space-around" style="position: absolute; top:50%; transform:translateY(-50%);">
        <v-row align="center" justify="space-around" class="px-15 px-lg-0 li-text-shadow text-center">
          <h1 class="text-h3  text-md-h1 white--text mb-5 font-weight-black" v-html="appConfig.pages.pageNotFound.title" />
        </v-row>
        <v-row align="center" justify="space-around" class="px-15 px-lg-0 li-text-shadow font-weight-bold">
          <p class="white--text mb-10" v-html="appConfig.pages.pageNotFound.subtitle" />
        </v-row>
        <v-row align="center" justify="space-around">
          <template v-for="(btn,n) in appConfig.pages.pageNotFound.buttons">
            <v-btn :to="btn.to" :color="btn.color" :class="btn.class" :key="n" class="px-10 bg-white" x-large>
              <h3 v-text="btn.text" />
            </v-btn>
          </template>
        </v-row>
      </v-container>
    </v-img>
  </v-container>
</template>

<script>
export default {
  title: "404 - Page not found",
  name: "PageNotFound"
};
</script>
